.alert-date {
  font-size: 0.8rem;
  margin-top: 10px;
}
  
.alert .alert-button {
  padding: 0;
  margin-left: auto;
  margin-right: 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
