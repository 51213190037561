/**
 *	Styles
 */

@import '../node_modules/animate.css/animate.css';

// The order of the imports matter!
@import 'facit/styles/index';
@import 'facit/styles/vendors/index';

// Custom styles
// @import 'src/pages/campaigns/products/styles/index'

td,
th {
  word-wrap: break-word;
  max-width: 20rem;
}

.hover-item {
  :hover {
    background-color: #d3d3d3;

  }
}

.test {
  text-decoration: none;
  cursor: pointer;
  color: #323232;

  &:hover {
    background-color: $gray-100;
    cursor: pointer;
  }

}